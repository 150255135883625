<template>
  <div class="mt-4">
    <div class="grey--text text--darken-2 font-weight-black">
      설명 <span class="caption grey--text">(선택사항)</span>
    </div>

    <v-textarea
      dense
      outlined
      no-resize
      hide-details
      placeholder="프로젝트의 배경과 목적을 공유하세요."
      v-model="description"
    />
    <!-- 
      개행때문에 일단 주석
      @keydown.enter="$emit('update:confirm')" 
      -->
  </div>
</template>

<style lang="scss" scoped>
.v-input.v-textarea::v-deep {
  .v-input__slot {
    fieldset {
      bottom: -1px !important;
    }
    textarea {
      margin-left: -1px;
    }
  }
}
</style>

<script>
export default {
  data({ $store: { state } }) {
    const { project } = state?.flowDialog?.params || {};
    return {
      description: project?.description || ""
    };
  },
  methods: {
    getData() {
      return { description: this.description };
    }
  }
};
</script>
